<template>
  <Modal 
      v-if="handoverModal.visible"
      :modal="handoverModal"
      @closeModal="handoverModal.visible = false"
      :cssClasses="{'is-full-height': true}"  
  >
      <template v-slot:header>
          HAND OVER / LENT OUT ITEMS FROM RESERVATION
      </template>
      <template v-slot>
          <div class="has-background-light is-full-width is-full-height p-1">
              <h1 class="is-size-5 m-2">Enter (valid) username to handover to user</h1>
              <input v-model="handoverReceivingUser" class="input is-third-width" type="text" placeholder="userid of user to give item to">
              <button class="button has-background-pink-1 has-text-light mr-1" @click="handOutItemsFromReservation">HANDOVER</button>
              <h1 class="is-size-5 m-2">OR</h1>
              <button class="button has-background-pink-1 has-text-light" @click="handOutItemsFromReservation($event,true)">TAKE BACK ITEMS</button>      
          </div>
      </template>
      <template v-slot:footer>
          <button class="button has-background-pink-1 has-text-light" @click="handoverModal.visible = false">CANCEL</button>
      </template>
  </Modal>
  <Modal 
      v-if="deleteModal.visible"
      :modal="deleteModal"
      @closeModal="deleteModal.visible = false"
      :cssClasses="{'is-full-height': true}"  
  >
      <template v-slot:header>
          DELETE RESERVATION?
      </template>
      <template v-slot class="is-full-width">
          <div class="has-background-light is-full-width is-full-height">
              <h1 class="is-size-5 m-2"> Delete reservation?</h1>
                Deleting this reservation will release all associated items.
          </div>
      </template>
      <template v-slot:footer>
          <button class="button has-background-green-1 has-text-light" @click="deleteModal.visible = false">CANCEL</button>
          <button class="button has-background-pink-1 has-text-light ml-6" @click="deleteReservation(deleteModal.reservation)">YES, DELETE</button>
      </template>
  </Modal>
  <div class="is-full-height is-overflow-scroll">
    <!-- 
      TODO: Add more filters for reservations:
      - [ ] show only reservations for me (reservation.for)
      - [ ] show only if in specific timeframe
    -->
    <div>
        <h1 class="is-size-4">RESERVATIONS</h1>
        <div>
          <div class="m-2 is-80pc-width is-flex">
            <input type="text" class="input is-small" ref="searchReservationsText" placeholder="searchtext"
            @keyup.enter="handleSearchClicked"/>
            <button class="button is-small mr-1" @click="handleSearchClicked">search</button>
            <div class="select is-small">
              <select @change="handleSortChanged">
                <option value="date">sort by date</option>
                <option value="name">sort by name</option>
              </select>
            </div>
          </div>
          
          <div class="m-1">
            <button class="button is-small is-rounded m-1" @click="toggleExcludePast">
            {{ excludePastReservations ? 'only future' : 'include past' }}
            </button>
            <button class="button is-small is-rounded m-1"  @click="toggleOnlyOwnedItems">
              {{ onlyOwnedItems ? 'my items only' : 'all items visible' }}
            </button>
            <button class="button is-small is-rounded m-1"  @click="toggleOnlyOwnedReservations">
              {{ onlyOwnedReservations ? 'my reservations' : 'all reservations' }}
            </button>
          </div>
        </div>
    </div>
    <div class="is-flex is-justify-content-center" v-for="reservation in reservations" :key="reservation">
      <div class="list-item list-item-flex-size ml-1 is-flex is-flex-direction-column has-background-lighter">
        <Collapsible @toggled="handleReservationToggled(reservation)" v-if="reservation.items.length > 0">
          <template v-slot:header>
            <h1 class="has-text-weight-bold">📅 {{ reservation.name  }}</h1>
            <div class="is-size-7">{{ reservation.id }}</div>
            <div>| {{ reservation.items.length }} x items |
              <span v-if="reservation.reservedfor"> for: {{ reservation.reservedfor }} |</span>
              <span v-if="reservation.confirmedby"> by: {{ reservation.confirmedby }} |</span>
            </div>
            <div class="is-full-width p-1">
              <input type="datetime-local" @click.stop :disabled="!reservation.toggled" name="from" v-model="reservation.reserved[0]">
              <br/><label for="till"> till </label>
              <input type="datetime-local" @click.stop :disabled="!reservation.toggled" name="till" v-model="reservation.reserved[1]">
              <span class="is-float-right">{{reservation.toggled ? "🔽":"▶️"  }}</span>
            </div>
            <div class="ml-2 mr-2">
              <textarea class="textarea" rows="5" @click.stop :disabled="!reservation.toggled" v-model="reservation.notes" />
            </div>
          </template>
            <template v-slot>
              <div>
                <button class="button is-small is-rounded has-background-pink-1 m-1 has-text-white" @click="handleDeleteReservationClicked(reservation)">DELETE</button>
                <button class="button is-small is-rounded has-background-green-4 m-1" @click="updateReservation(reservation)">UPDATE</button>
                <button class="button is-small is-rounded has-background-blue-1 m-1 has-text-white" @click="addItemsFromBasketToReservation(reservation)">ADD ITEMS FROM BASKET</button>
                <button class="button is-small is-rounded has-background-yellow-1 m-1" @click="handlehandOutItemsClicked(reservation)">HAND OUT ITEMS</button>
                <div v-for="item in reservation.itemsJSON" :key="item" class="has-background-white m-2">
                  <ListItem v-if="item" :item="item" @click="this.$router.push(`/Detail?id=${item.id}`)" 
                    :swipetoremove="item.notOwnedByUser ? false :true" :removebutton="item.notOwnedByUser ? false : true" @swipeRemove="removeItemsFromReservation(item,reservation)" 
                    style="width: 100%; margin: 0;"/>
                </div>
              </div>
            </template>
        </Collapsible>
      </div>
      
    </div>
    <br/><br/><br/>
  </div>
</template>

<script>
import ListItem from '@/components/Template/ListItem'
import { onMounted, ref } from '@vue/runtime-core';
import Collapsible from '@/components/Template/Collapsible.vue'
import Modal from '@/components/Template/Modal.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';
export default {
    name: 'Reservations',
    components:{
      Collapsible,
      ListItem,
      Modal,
    },
    props: {
        startImageArray: Array,
    },
    setup(){
        const store = useStore();
        const route = useRoute();
        const reservations = ref([]);

        const deleteModal = ref({visible:false})

        let activeSortFilter = 'date';
        const sortFiltersMap = { // TODO: add ascending/descending
          'date': function(a,b){return new Date(a.reserved[0]) - new Date(b.reserved[0]);},
          'name': function (a, b) {if(a.name < b.name){return -1;} if(a.name > b.name){return 1;}return 0;}
        };

        const excludePastReservations = ref(true);
        const onlyOwnedItems = ref(false);
        const onlyOwnedReservations = ref(false);
        const searchReservationsText = ref(null);
        // const searchReservationsName = ref(null);

        onMounted(async()=>{
          if (route.query.search){
            searchReservationsText.value.value = route.query.search;
          }
           await init();
        })

        async function init(){
          const returnval = await store.dispatch('getAllReservations',{
            onlyOwnedItems:onlyOwnedItems.value, 
            excludePastReservations: excludePastReservations.value,
            onlyOwnedReservations: onlyOwnedReservations.value,
            searchReservationsText: searchReservationsText.value.value,
            // searchReservationsName: searchReservationsName.value.value,
          });
            returnval.reservations.forEach((r)=>{
              r.toggled = false;
              console.log(r);
            })
            reservations.value = returnval.reservations;
            sortReservations();
            //console.log(reservations);
        }

        function handleSearchClicked(){
          init();
        }

        function toggleExcludePast(){
          excludePastReservations.value = !excludePastReservations.value;
          init();
        }

        function toggleOnlyOwnedItems(){
          onlyOwnedItems.value = !onlyOwnedItems.value;
          init();
        }

        function toggleOnlyOwnedReservations(){
          onlyOwnedReservations.value = !onlyOwnedReservations.value;
          init();
        }

        function sortReservations(){
          reservations.value = reservations.value.sort(sortFiltersMap[activeSortFilter]);
        }

        async function handleSortChanged(e){
          const sortType = e.target.value;
          activeSortFilter = sortType;
          sortReservations();
        }

        async function handleReservationToggled(reservation){
          reservation.toggled = !reservation.toggled;
          if (reservation.toggled && !reservation.itemsJSON){
            console.log(`reservation ${reservation.id} has no fetched items yet. will fetch now...`);
            let fetchedItems = []
            for (let item of reservation.items){
                let itemObj = await store.dispatch('getItem',item);
                console.log(itemObj,itemObj instanceof Error,itemObj.stack,itemObj.message);
                if (itemObj instanceof Error){
                  console.warn('could not fetch item',item,'you might not have permission to read this item')
                  fetchedItems.push({notOwnedByUser:true,id:'',name:`❓ unkown item. do you have permissions to read this❓ ${item}`, pictures:['/assets/manifest-icon-192.maskable.png']});

                  continue
                }
                fetchedItems.push(itemObj);
            }
            
            reservation.itemsJSON = fetchedItems;
          }
        }

        function handleDeleteReservationClicked(reservation){
          deleteModal.value.visible=true;
          deleteModal.value.reservation=reservation;
        }

        async function deleteReservation(reservation){
          let reservationid = reservation.id;
          await store.dispatch('deleteReservation',{id:reservationid});
          deleteModal.value.visible = false;
          setTimeout(init,1000)
        }

        async function removeItemsFromReservation(item,reservation){
          let reservationid = reservation.id;
          await store.dispatch('removeItemFromReservation',{reservationid,items:[item]});
          console.log(reservationid,item.id);
          reservation.items = reservation.items.filter((i)=>i !== item.id);
          reservation.itemsJSON = reservation.itemsJSON.filter((i)=>i.id !== item.id);
        }

        async function updateReservation(reservation){
          console.log("updateREservation",reservation);
          await store.dispatch('updateReservation',{reservation})
        }

        async function addItemsFromBasketToReservation(reservation){
          let items2reserve = store.state.data.basket;
          console.log("adding items",items2reserve,"from basket to reservation",reservation);
          let result = await store.dispatch("addItemsToReservation",{
            reservationid:reservation.id,
            items:items2reserve})
          if (result?.status == 200){ // TODO: DO BETTER! only update reservation in question
            setTimeout(init,1000);
          }
        }
        
        const handoverReceivingUser = ref(null);
        const handoverModal = ref({visible:false})
        let handoverItems = [];

        async function handlehandOutItemsClicked(reservation){
          handoverModal.value.visible = true;
          handoverItems = reservation.items;
        }

        const handOutItemsFromReservation = async (e,takeback)=>{
          console.log(e,takeback);
          let handOverto = [handoverReceivingUser.value]
          if (takeback){handOverto=[]}
          if (!takeback && !handoverReceivingUser.value){
            return store.dispatch('triggerToast',{message:'username must be provided!'})
          }
          let result = await store.dispatch('handoverItems',{item_ids:handoverItems,to:handOverto})
          if (result) {
            handoverModal.value.visible = false;
            init();
          }
        }

        return {
          handleReservationToggled,
          handleDeleteReservationClicked,
          deleteReservation,
          reservations,
          removeItemsFromReservation,
          deleteModal,
          updateReservation,
          addItemsFromBasketToReservation,
          handOutItemsFromReservation,
          handoverModal,
          handlehandOutItemsClicked,
          handoverItems,
          handoverReceivingUser,
          handleSortChanged,
          excludePastReservations,
          onlyOwnedItems,
          toggleExcludePast,
          toggleOnlyOwnedItems,
          searchReservationsText,
          handleSearchClicked,
          onlyOwnedReservations,
          toggleOnlyOwnedReservations
        }
    }
}
</script>

<style>

</style>